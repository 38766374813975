@import "../../foundation/setting";



/*上書き
---------------------------------------------------------- */
.l-content {
	padding-top: 2rem;
}



/*見出し
---------------------------------------------------------- */
.headline-lv01 {
	position: relative;
	margin-bottom: 1.2rem;
	font-size: 1.8rem;
	font-weight: bold;
	@include line-height(24, 18);
	@include mq('max') {
		font-size: 1.6rem;
		@include line-height(22, 16);
	}
}

.headline-lv01--sub {
	display: inline-block;
	font-size: 1.4rem;
	font-weight: 400;
	@include line-height(20, 14);
	@include mq('min') {
		padding-left: 0.8rem;
	}
	@include mq('max') {
		display: block;
		position: relative;
		left: -2.2rem;
		padding-top: 0.6rem;
		font-size: 1.2rem;
		@include line-height(18, 12);
	}
}

.headline-lv02 {
	position: relative;
	margin-bottom: 1.2rem;
	padding: 1.2rem;
	font-size: 1.8rem;
	font-weight: bold;
	@include line-height(24, 18);
	background-color: #DFF2FF;
	@include mq('max') {
		font-size: 1.6rem;
		@include line-height(22, 16);
	}
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4px;
		background-color: #81c6f8;
		@include mq('max') {
			height: 2px;
		}
	}
}

.headline-mark {
	display: inline-block;
	position: relative;
	padding-left: 2.6rem;
	@include mq('max') {
		padding-left: 2.2rem;
	}
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: .12em;
		bottom: .02em;
		left: 0;
		width: 2rem;
		height: 2rem;
		background: url(../../../img/common/logo-mark.png) no-repeat;
		background-size: 2rem auto;
		@include mq('max') {
			top: .22em;
			bottom: .12em;
			width: 1.6rem;
			height: 1.6rem;
			background-size: 1.6rem auto;
		}
	}
}



/*ヒーロー
---------------------------------------------------------- */
.hero {
	margin-bottom: 2rem;
}

.hero__content {
	@include mq('min') {
		display: flex;
	}
}

.hero__carousel {
	@include mq('min') {
		flex: 1 1 0%;
		padding-right: 1rem;
	}
	@include mq('max') {
		margin-bottom: 2rem;
	}
}

.hero__carousel-container {
	.slick-dots {
		position: absolute;
		bottom: 1rem;
	}
}

.hero__bnr {
	@include mq('min') {
		width: 150px;
	}
}

.hero__bnr-items {
	@include mq('max') {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: -1rem 0 0 -1rem;
	}
}

.hero__bnr-item {
	@include mq('min') {
		&:not(:first-child) {
			margin-top: 1rem;
		}
	}
	@include mq('max') {
		margin: 1rem 0 0 1rem;
		
	}
}

.hero__bnr-item-link {
	display: inline-block;
	vertical-align: top;
	@include opacity();
}



/*バナー
---------------------------------------------------------- */
.bnr {
	margin-bottom: 2rem;
}

.bnr__item-link {
	display: block;
	position: relative;
	vertical-align: top;
	@include opacity();
}


.bnr__items {
	margin-top: 1rem;
	display: flex;
	@include mq('max') {
		margin: 0 0 0 0;
		justify-content: center;
		flex-wrap: wrap;
	}
}

.bnr__item {
	width: calc(50% - 1rem);
	flex-grow:1;
	&:not(:first-child) {
		margin-left: 1rem;
	}
	@include mq('max') {
		width: 100%;
		margin: 1rem 0 0.5rem 0;
		&:not(:first-child) {
			margin-left: 0;
		}
	}
}




/*情報
---------------------------------------------------------- */
.info__inner {
	margin-bottom: 2rem;
	padding: 2rem;
	background-color: #F2F2F2;
	@include mq('max') {
		padding: 1.2rem;
	}
}

.info__items {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: -1rem 0 0 -1rem;
	@include mq('max') {
		margin: -0.5rem 0 0 -0.5rem;
	}
}

.info__item {
	width: calc(16.6666667% - 1rem);
	margin: 1rem 0 0 1rem;
	border: 1px solid #ddd;
	@include mq('max') {
		width: calc(50% - 0.5rem);
		margin: 0.5rem 0 0 0.5rem;
	}
}

.info__item-link {
	display: block;
	position: relative;
	text-align: center;
	padding: 0.6rem 0.1rem;
	border: 1px solid $color-white;
	color: $color-main;
	background-color: #ebf8ff;
	@include opacity();
	@include mq('max') {
		img[src*=icon_info01] {
			width: 1.6rem;
		}
		img[src*=icon_info02] {
			width: 2.3rem;
		}
		img[src*=icon_info03] {
			width: 2.7rem;
		}
		img[src*=icon_info04] {
			width: 2.7rem;
		}
		img[src*=icon_info05] {
			width: 2.7rem;
		}
		img[src*=icon_info06] {
			width: 2.3rem;
		}
	}
	&:before {
		content: icon($icon-triangle-half-left);
		@include icomoon();
		display: inline-block;
		position: absolute;
		right: 0;
		bottom: 0;
		font-size: 10px;
		color: $color-main;
		transform: scale(0.7);
		transform-origin: right bottom;
	}
}

.info__item-text {
	margin-bottom: 0.8rem;
	font-size: 1.5rem;
	font-weight: bold;
	@include line-height(22, 15);
	@include mq('max') {
		margin-bottom: 0.6rem;
		font-size: 1.3rem;
		@include line-height(20, 13);
	}
}


/*ボランティア
---------------------------------------------------------- */
.volunteer__inner {
	margin-bottom: 2rem;
	padding: 2rem;
	border: 3px solid $color-maintone01;
	@include mq('max') {
		padding: 1.2rem;
		border-width: 2px;
	}
}

.volunteer__text > * {
	&:not(:first-child) {
		margin-top: 0.6rem;
	}
}

